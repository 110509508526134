/* Custom page CSS
-------------------------------------------------- */

/* Not required for template or sticky footer method. */

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #5f6368;
  -webkit-box-shadow: 0 0 1px #5f6368;
}

.modal-lg,
.modal-xl {
  --bs-modal-width: 646px;
}

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem;
  /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}


main>.container {
  padding: 30px 15px 0;
}

.footer>.container {
  padding-right: 15px;
  padding-left: 15px;
  /* text-align: center; */
}

.container.mt-main {
  margin-top: 5rem !important;
}

.navbar-light {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

code {
  font-size: 80%;
}

.logo {
  margin-right: 5px;
  vertical-align: top;
  width: 32px;
  height: 32px;
}

.logo-text {
  color: #5f6368;
  font-weight: bolder;
}

.logo-text:hover {
  color: #5f6368;
}


footer .list-inline>li>a::after {
  content: "|";
  padding-left: 10px;
}

footer .list-inline>li:last-child>a::after {
  content: none;
}

pre {
  overflow: visible;
}

.lang-list .list-inline>li>a::after {
  content: "|";
  padding-left: 10px;
}

.lang-list .list-inline>li:last-child>a::after {
  content: none;
}

.counts-info {
  font-size: small;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.btn-wrap {
  white-space: normal !important;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
